<template>
    <v-row justify="center">
        <v-dialog v-model="sasDialog" width="900px" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" text v-bind="attrs" v-on="on" small @click="loadItem(item)">
                    <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="my-5">
                    {{ item.sp_primary_details.account_name }}
                </v-card-title>
                <v-card-text class="mt-5">

                    <h3 class="mb-5">Business Information</h3>

                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Mother Location" hide-details="auto" outlined
                                v-model="otherPrimaryDetails.mother_location" dense>
                            </v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-autocomplete label="Class Code" hide-details="auto" outlined
                                v-model="otherPrimaryDetails.class_code_entity" dense :items="items">
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Company ID" hide-details="auto" outlined
                                v-model="otherPrimaryDetails.company_id" dense>
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="mt-2">
                        <v-col>
                            <v-btn outlined color="primary" style="float:right;" @click="save"
                                v-if="otherPrimaryDetails.mother_location && otherPrimaryDetails.class_code_entity && otherPrimaryDetails.company_id">
                                Update Business Information
                            </v-btn>
                            <v-btn outlined color="primary" style="float:right;" v-else disabled>
                                Update Business Information
                            </v-btn>
                        </v-col>
                    </v-row>

                    <h3 class="my-5">Branches</h3>

                    <v-expansion-panels accordion>
                        <v-expansion-panel v-for="(parentItem, parentIndex) in existingUploadedBranches"
                            :key="parentIndex" class="mb-3">
                            <v-expansion-panel-header>{{ parentItem.branch_name }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <!-- Set the location_code to readonly, because this will be system generated upon update of the SalesProspect -->
                                        <v-text-field label="Location Code" v-model="parentItem.location_code" outlined
                                            hide-details="auto" dense readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-autocomplete label="*Class Code" v-model="parentItem.class_code_branch"
                                            outlined hide-details="auto" dense :items="items">
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>

                                <h4 class="mt-5">FLA</h4>

                                <v-expansion-panels accordion class="mt-5">
                                    <v-expansion-panel v-for="(fla, index) in parentItem.fla" :key="index">
                                        <v-expansion-panel-header>{{ fla.first_name }} {{ fla.middle_name }}
                                            {{ fla.last_name }}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-col cols="9">
                                                <v-text-field label="*Agent Code" outlined dense hide-details="auto"
                                                    hide-spin-buttons v-model="fla.agent_code"></v-text-field>
                                            </v-col>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                                <v-row class="mt-2">
                                    <v-col>
                                        <v-btn outlined color="primary" style="float:right;"
                                            @click="updateBranch(parentItem, parentIndex)">
                                            Update Branch
                                        </v-btn>
                                    </v-col>
                                </v-row>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-pagination @input="changePage" v-model="page" :total-visible="5"
                        :length="branchPage.length"></v-pagination>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="error" text @click="cancelDialog">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import jsonToFormData from 'json-form-data';
import _ from 'lodash';
export default {
    props: ['item'],
    data() {
        return {
            sasDialog: false,
            items: ["Send and Receive", "Send Only", "Receive Only"],
            spNumber: '',
            page: 1,
            branchPage: []
        }
    },
    computed: {
        ...mapGetters({
            sasDetails: 'salesProspecting/sasDetails',
            existingUploadedBranches: 'salesProspecting/existingUploadedBranches',
            otherPrimaryDetails: 'salesProspecting/otherPrimaryDetails'
        })
    },
    watch: {
        sasDialog: {
            handler(value) {
                if (value === true) {
                    this.reloadData()
                }
            },
            deep: true
        },
    },
    methods: {
        async reloadData() {
            console.log(this.item)
            const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.item.id)
            if (res.status === 200) {
                this.countBranchPage(res.data.result[0].sp_branch)
                this.decodeBranches(res.data.result[0].sp_branch.data)
            }
        },
        countBranchPage(e) {
            this.page = 1
            this.branchPage = []
            if (e) {
                let number = e.last_page
                for (let i = 1; i <= number; i++) {
                    this.branchPage.push(i)
                }
            }
        },
        async changePage(e) {
            const payload = {
                id: this.item.id,
                page: e
            }
            const res = await this.$store.dispatch('salesProspecting/changePageBranch', { payload })
            if (res.status === 200) {
                this.decodeBranches(res.data.result[0].sp_branch.data)
            }
        },
        cancelDialog() {
            this.$store.commit('salesProspecting/SET_EXISTING_BRANCH_DEFAULT')
            this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS_DEFAULT')
            this.sasDialog = false
        },
        async loadItem(i) {
            console.log(i)
            this.spNumber = i.id
            const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', i.id)
            this.$store.commit('salesProspecting/SET_SAS_DETAILS', res.data.result[0])
            this.reloadBranches()
            this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS', res.data.result[0].sp_other_primary_details)
        },
        updateBranch(item) {
            var options = {
                initialFormData: new FormData(),
                howLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function (value) {
                    if (typeof value === 'boolean') {
                        return +value ? '1' : '0';
                    }
                    return value;
                }
            }
            var initialPayload = { ...item }
            console.log(initialPayload)
            delete initialPayload.id
            delete initialPayload.created_at
            delete initialPayload.updated_at
            delete initialPayload.businessPermitUploaded
            delete initialPayload.justificationUploaded
            delete initialPayload.justificationUploaded
            initialPayload.fla.forEach(function (e) {
                if (e.id) {
                    e.fla_id = e.id
                }
                if (e.nbiUploaded) {
                    delete e.nbiUploaded
                }
                if (e.drugTestUploaded) {
                    delete e.drugTestUploaded
                }
                if (e.amlaUploaded) {
                    delete e.amlaUploaded
                }
                delete e.created_at
                delete e.updated_at
                delete e.menuExpiry
            })
            var convertedFormData = jsonToFormData(initialPayload, options)
            const payload = { id: this.spNumber, payload: convertedFormData }
            this.callUpdateBranch(payload)
        },
        async callUpdateBranch(payload) {
            await this.$store.dispatch('salesProspecting/doUpdateBranch', { payload })
            this.reloadBranches()
        },
        async reloadBranches() {
            const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.spNumber)
            res.data.result[0].sp_branch.data.map(coords => {
                coords.coordinates = JSON.parse(coords.coordinates)
                coords.coordinates.lat = parseFloat(coords.coordinates.lat)
                coords.coordinates.lng = parseFloat(coords.coordinates.lng)
            })
            this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_CITY_DEFAULT')
            this.$store.commit('salesProspecting/SET_EXISTINGBRANCHES_ZIP_DEFAULT')
            this.decodeBranches(res.data.result[0].sp_branch.data)
        },
        decodeBranches(resBranches) {
            let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
            let nonExpiryFile = {
                file: null
            }
            resBranches.forEach(function (e) {
                e.branch_id = e.id
                e.businessPermitUploaded = JSON.parse(e.business_permit)
                e.justificationUploaded = JSON.parse(e.justification)
                e.operating_hours = JSON.parse(e.operating_hours)
                e.operating_hours.forEach(function (g) {
                    g.isClosed = parseInt(g.isClosed)
                })
                e.business_permit = _.clone(expiryFile)
                e.justification = _.clone(nonExpiryFile)
                e.fla.forEach(function (f) {
                    f.amlaUploaded = JSON.parse(f.amla)
                    f.drugTestUploaded = JSON.parse(f.drug_test)
                    f.nbiUploaded = JSON.parse(f.nbi)
                    f.amla = _.clone(expiryFile)
                    f.drug_test = _.clone(expiryFile)
                    f.nbi = _.clone(nonExpiryFile)
                })
            })
            this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_BRANCHES', resBranches)
        },
        save() {
            const initialPayload = {
                class_code_entity: this.otherPrimaryDetails.class_code_entity,
                company_id: this.otherPrimaryDetails.company_id,
                mother_location: this.otherPrimaryDetails.mother_location,
            }
            const payload = { id: this.spNumber, payload: initialPayload }
            console.log(payload)
            this.callSave(payload)
        },
        async callSave(payload) {
            const res = await this.$store.dispatch('salesProspecting/doUpdateBusinessInformation', { payload })
            if (res.status === 200) {
                console.log(res)
                const respond = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.spNumber)
                this.$store.commit('salesProspecting/SET_OTHER_PRIMARY_DETAILS', respond.data.result[0].sp_other_primary_details)
            }
        }
    },
}
</script>
